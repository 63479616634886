import React from "react"

const MailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.711 254.145">
    <g id="undraw_message_sent_1030" transform="translate(-145.633 -102.571)">
      <path
        id="Path_28"
        data-name="Path 28"
        d="M582.351,565.081H361.533a27.3,27.3,0,0,1-27.3-27.3V436.61a10.817,10.817,0,0,1,3.375-7.85L454.616,317.842a25.184,25.184,0,0,1,34.651,0L600.831,423.6a28.273,28.273,0,0,1,8.823,20.519v93.662a27.3,27.3,0,0,1-27.3,27.3Z"
        transform="translate(-188.31 -208.365)"
        fill="#364156"
      />
      <path
        id="Path_29"
        data-name="Path 29"
        d="M240.954,387.992H514.083V498.315A16.487,16.487,0,0,1,497.6,514.8H257.441a16.487,16.487,0,0,1-16.487-16.487Z"
        transform="translate(-95.035 -158.086)"
        opacity="0.1"
      />
      <path
        id="Path_30"
        data-name="Path 30"
        d="M333.73,521.351"
        transform="translate(-188.097 -298.044)"
        fill="none"
      />
      <rect
        id="Rectangle_39"
        data-name="Rectangle 39"
        width="203.125"
        height="203.125"
        rx="26.181"
        transform="translate(181.782 142.975)"
        fill="#6c63ff"
      />
      <path
        id="Path_31"
        data-name="Path 31"
        d="M506.579,395.942V507.575H303.454V395.942l101.563,49.089Z"
        transform="translate(-121.672 -161.474)"
        opacity="0.1"
      />
      <path
        id="Path_32"
        data-name="Path 32"
        d="M471.442,590.241l-130.134-62.9a5.28,5.28,0,0,0-7.578,4.754V640.316A16.487,16.487,0,0,0,350.216,656.8H592.667a16.487,16.487,0,0,0,16.487-16.487V533.951a6.443,6.443,0,0,0-9.247-5.8Z"
        transform="translate(-188.097 -300.373)"
        fill="#364156"
      />
      <rect
        id="Rectangle_40"
        data-name="Rectangle 40"
        width="56.806"
        height="8.033"
        transform="translate(199.283 161.624)"
        fill="#f2f2f2"
      />
      <rect
        id="Rectangle_41"
        data-name="Rectangle 41"
        width="168.697"
        height="4.59"
        transform="translate(194.693 190.888)"
        fill="#f2f2f2"
      />
      <rect
        id="Rectangle_42"
        data-name="Rectangle 42"
        width="168.697"
        height="4.59"
        transform="translate(194.693 206.38)"
        fill="#f2f2f2"
      />
      <rect
        id="Rectangle_43"
        data-name="Rectangle 43"
        width="168.697"
        height="4.59"
        transform="translate(194.693 221.873)"
        fill="#f2f2f2"
      />
      <ellipse
        id="Ellipse_9"
        data-name="Ellipse 9"
        cx="23.526"
        cy="23.569"
        rx="23.526"
        ry="23.569"
        transform="translate(166.576 122.605)"
        fill="#fff"
      />
      <path
        id="Path_54"
        data-name="Path 54"
        d="M390.346,338.851a28.116,28.116,0,1,0,28.116,28.116A28.116,28.116,0,0,0,390.346,338.851Zm-5.772,43.107-14.41-14.41,4.042-4.042,10.381,10.381L406.5,351.976l4.042,4.042-25.967,25.94Z"
        transform="translate(-200.244 -220.262)"
        fill="#57b894"
      />
    </g>
  </svg>
)

export default MailIcon
