import React from "react"
import Line from "./line"

const PageTitle = ({ children }) => (
  <>
    <Line />
    <h1
      style={{
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      {children}
    </h1>
    <Line />
  </>
)

export default PageTitle
