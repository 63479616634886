import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Subscribe from "../components/subscribe"
import PageTitle from "../components/page-title"
import styled from "styled-components"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Página não encontrada" />
        <PageTitle>Página não encontrada</PageTitle>
        <p style={{ marginTop: "40px" }}>Esta página não existe.</p>

        <Link to="/">
          <Button>Voltar ao inicio</Button>
        </Link>

        <Subscribe />
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Button = styled.button`
  color: var(--white);
  background: var(--primary);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 10px;
  padding: 10px;
  outline: 0;
  border: 1px solid var(--form-bg);
  color: var(--white);
`
