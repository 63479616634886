import React from "react"
import styled from "styled-components"
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants"
import { rhythm } from "../utils/typography"
import MailIcon from "./mail-icon"
import { navigate } from "gatsby"

const Subscribe = () => {
  return (
    <Form
      name="subscribe"
      method="POST"
      action="https://assets.mailerlite.com/jsonp/398227/forms/85148862165026160/subscribe"
      onSubmit={e => {
        e.preventDefault()
        const form = e.target
        const data = new FormData(form)
        const xhr = new XMLHttpRequest()
        xhr.open(form.method, form.action)
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return
          if (xhr.status === 200) {
            navigate("/s/bem-vindo")
          } else {
            // alert("Ocorreu um erro, tente novamente mais tarde.")
          }
        }
        xhr.send(JSON.stringify({ fields: Object.fromEntries(data) }))
      }}
    >
      <div style={{ width: "100px", display: "inline-block" }}>
        <MailIcon />
      </div>
      <h4 style={{ textTransform: "uppercase", marginTop: "20px" }}>
        Subscreva
      </h4>
      <p>
        Para além dos conteúdos mais recentes do site, receberá, todas as
        segundas&#8209;feiras, uma newsletter exclusiva com os conteúdos mais
        interessantes que li e vi sobre futebol, mas também relacionados com
        ciências sociais e humanas, áreas, que, acredito, devem ser cada vez
        mais enquadradas no futebol.
      </p>
      <ButtonsGroup>
        <TextInput type="text" placeholder="O seu nome" name="name" required />
        <TextInput
          type="email"
          placeholder="O seu email"
          name="email"
          required
        />
        <Button type="submit">Subscrever</Button>
      </ButtonsGroup>
      <small>Pode cancelar a subscrição em qualquer altura</small>
    </Form>
  )
}

const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${MOBILE_MEDIA_QUERY} {
    flex-direction: column;
  }

  input,
  button {
    padding: 10px;
    outline: 0;
    border: 1px solid var(--form-color);
  }
`

const Button = styled.button`
  color: var(--form-bg);
  background: var(--form-color);
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  font-weight: 700;
  margin-top: 10px;

  ${MOBILE_MEDIA_QUERY} {
    border-radius: 4px;
    width: 100%;
  }
`

const TextInput = styled.input`
  background: none;
  border-radius: 4px 0 0 4px;
  color: var(--form-color);
  margin-top: 10px;
  width: 35%;

  &:nth-child(2) {
    border-radius: 0;

    ${MOBILE_MEDIA_QUERY} {
      margin: 10px 0;
      border-radius: 4px;
    }
  }

  ${MOBILE_MEDIA_QUERY} {
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid var(--form-color);
    width: 100%;
  }
`

const Form = styled.form`
  box-shadow: var(--form-shadow);
  color: var(--form-color);
  border-radius: 3px;
  padding: 40px 20px;
  margin-top: ${rhythm(3)};
  margin-bottom: ${rhythm(3)};
  margin-left: ${rhythm(-1)};
  width: calc(100% + ${rhythm(2)});
  background: var(--form-bg);
  text-align: center;
  font-family: Montserrat;
`

export default Subscribe
